body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #173340;
}
*:focus {
  box-shadow: none !important;
}
.App {
  overflow-x: hidden;
}
.stickyHeader {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  background-color: #ffffff;
  box-shadow: 0 2px 0px -1px rgba(0, 0, 0, 0.05);
  z-index: 2;
}

input[type="file"] {
  display: none;
}

.custom-file-name {
  border-radius: 4px;
  font-size: 0.9em;
  border: 1px solid #e4e4e4;
  background-color: transparent;
  display: inline-block;
  padding: 3px 12px 6px 12px;
  margin-top: 10px;
  color: #363636;
}
.custom-file-upload {
  border-radius: 4px;
  font-size: 0.9em;
  /* border: 1px solid #e4e4e4; */
  background-color: transparent;
  display: inline-block;
  padding: 3px 12px 6px 12px;
  margin-top: 10px;
  color: #008cff;
  cursor: pointer;
}
.custom-file-upload:hover {
  color: #727272;
}

.topPanel {
  position: relative;
  z-index: 3 !important;
}
.notesBox {
  position: relative;
  margin: 0 auto;
  background-color: #ffffff;
}
.modalheader {
  margin-top: 20px;
  font-size: 1em !important;
}
.tox.tox-tinymce-aux {
  z-index: 4000;
}
.mask {
  position: relative;
  width: 120px;
  height: 40px;
  margin: 0 auto;
  padding: 0px;
}
.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}
.closeModal {
  position: absolute;
  right: 0px;
}
.calendarpage {
  position: relative;
  overflow-x: hidden;
}
.tableContainer {
  position: relative;
  margin: 0 auto;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.calendarprogress {
  position: relative;
  text-align: center;
}
.calendartitle p {
  padding: 0px 0px 0px 20px;
}
.bigger {
  font-size: 1.1em;
}
.calendartitle {
  font-family: "Arial";
  width: auto;
  text-align: center;
  position: absolute;
  padding: 12px 0px 0px 0px;
  height: 20px;
  white-space: nowrap;
  font-size: 0.8em;
  margin-left: 0px;
  color: #3a4f54;
  z-index: 1;
}
.donetaskwraper {
  padding: 4px 0px 0px 0px;
}

.addsubcal {
  position: relative;
  left: 4px;
  top: 0px;
}
.addsubcalempty {
  position: relative;
  left: 67px;
  top: 7px;
}
.calendarbutton {
  left: 0px;
  min-width: 140px;
  text-align: center;
  position: absolute;
  padding: 0px 0px 0px 0px;
  height: 20px;
  white-space: nowrap;
  font-size: 0.8em;
  z-index: 101;
}
.container {
  position: relative;
  padding: 7px 0px 0px 0px;
  width: 21px;
  height: 38px;
  border-radius: 0px;
  border: 0px solid transparent;
  text-align: right;
  z-index: 100;
}
.cointeinermain {
  left: 0px;
  background-color: white;
  /* background-color: #5dc7f5; */
}
.cointeinersub {
  left: 15px;
  /* background-color: #9ddffc; */
}
.showsubsbutton {
  position: relative;
  z-index: 1;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: -380px;
  right: 0;
  top: -0px;
  overflow: hidden;
  width: 400px;
  height: 38px;
  border-radius: 3px;
  transition: 0.5s ease;
  /* border: 1px solid #ffffff; */
  box-shadow: -5px 5px 10px 5px rgba(0, 0, 0, 0.1);
}
.overlaymain {
  background-color: #5dc7f5;
}
.overlaysub {
  background-color: #9ddffc;
}

.container:hover .overlay {
  width: 400px;
  left: -10px;
}

.taskpanel {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 2px;
  overflow: hidden;
  top: 50%;
  left: 53%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.boxpanel {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.calendartd {
  position: relative;

  height: 20px;
  border-width: 1px 1px 0px 0px;
  border-color: #ffffff;
  z-index: 100;
}
.calendarTh {
  position: absolute;
  border: 1px solid red;
}
.calendartd:hover {
  cursor: pointer;
  cursor: hand;
  background-color: rgba(202, 232, 252, 0.35) !important;
}
.todaytd {
  position: relative;
  height: 39px;
  border-radius: 2px !important;
  border-top: 1px solid #ffffff;
  border-right: 5px solid #5bc3ff;
  border-bottom: 0px solid #ffffff;
  border-left: 1px solid #ffffff;
  z-index: 100;
}
.todaytd:hover {
  cursor: pointer;
  cursor: hand;
  background-color: rgba(202, 232, 252, 0.35) !important;
}
.tableCalendar {
  margin-bottom: 50px;
  border-collapse: collapse;
  border-spacing: 0;
  margin-left: 135px;
}
.tableCalendar td {
  min-width: 0px;
}
.todayth {
  background-color: #d1d1d1;
  padding: 0px 5px 1px 5px;
  border-radius: 5px;
  color: #ffffff;
}
.tableCalendar th:hover {
  text-align: center;
  background-color: none !important;
}
.tableCalendar th {
  cursor: pointer;
  cursor: hand;
  text-align: center;
  height: auto;
  width: 10px;
  font-size: 0.9em;
  white-space: nowrap;
  color: #b1b1b1;
}
.tableCalendar th div {
  margin-left: -250px;
  text-align: start;
  display: inline-block;
}
.thmonth {
  color: #102833;
  padding: 4px 2px;
  font-weight: 300;
  background-color: #ffffff;
  border-radius: 3px;
  text-transform: uppercase;
}
.thday p {
  min-width: 30px;
  background-color: #90cdf4;
  padding: 5px 2px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 0.85em;
  color: #ffffff;
  /* border-width: 1px 1px 0px 1px;
  border-color: #eeeeee; 
  border-style: solid; */
}
.thday:hover p {
  background-color: #e8eef7;
  font-weight: 600;
  font-size: 0.85em;
  color: hsl(204, 100%, 59%);
  /* border-width: 1px 1px 0px 1px;
  border-color: #eeeeee; 
  border-style: solid; */
}
.thday:nth-last-child() {
  border-width: 0px 1px 0px 1px;
  border-color: #eeeeee;
  border-style: solid;
}
.thtoday {
  font-weight: 200;
  border-width: 1px 1px 0px 1px;
  border-color: #eeeeee;
  border-style: solid;
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.selected {
  background-color: red;
}
.calendarpopup {
  position: relative;
  border: 1px solid red;
  box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.05);
  /* z-index: 100; */
}
.calendardatepicker {
  background: #ffffff;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 0.9em;
}
.calendardatepicker input {
}
.datepicker {
  position: relative;
  width: 130px;
  z-index: 102222;
}
.datepicker input {
  position: relative;
  font-size: 0.9em;
  width: 130px;
  z-index: 102222;
}
.noBorder {
  border: none !important;
}
.nomargin {
  margin: 0px !important;
  padding: 0px !important;
}
.drawerInfo {
  padding: 0px !important;
  font-size: 0.8em;
  color: #195688;
}
.alarmimg {
  position: relative;
  width: 15px;
}
.basicGreen {
  color: #9fba3c;
}
.subtaskTrZero {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
}
.strong {
  font-weight: bold;
}
.subtaskTrFirstTop {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 10px 0px 0px 0px;
}
.subtaskTrFirstBottom {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 0px 0px 0px 10px;
}
.subtaskTrLastTop {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 0px 10px 0px 0px;
}
.subtaskTrLastBottom {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 0px 0px 10px 0px;
}
.subtaskTrFirstAll {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 10px 0px 0px 10px;
}
.subtaskTrLastAll {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 0px 10px 10px 0px;
}
.subtaskTr {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
}
.subtaskTrBreak {
  position: relative;
  margin: 0px !important;
  padding: 10px !important;
  border: none !important;
}

.drawersubtask {
  color: #aaaaaa;
  margin: 2px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  cursor: hand;
}
.subtaskwraper {
  position: relative;
  margin: 10px;

  background-color: #fafafa;
  cursor: pointer;
  cursor: hand;
}

.subtaskwraper:hover {
  background-color: #ffffff !important;
}
.leftbar {
  border-radius: 0px 3px 3px 0px;
  position: absolute;
  display: block;
  height: 100%;
  left: 0px;
  top: 0px;
  width: 11px;
  cursor: pointer;
  cursor: hand;
}
.subtaskwraper:hover > .leftbar,
.leftbar:hover {
  position: absolute;
  display: block;
  height: 100%;
  left: 0px;
  top: 0px;
  width: 18px;
}

.drawersubtaskSelected:hover {
  /* border: 1px solid #90cdf4; */
}
.drawersubtaskSelected {
  /* border: 1px solid #90cdf4; */
  margin: 2px;
  padding: 10px;
  margin: 5px;
  background-color: #ffffff;
  cursor: pointer;
  cursor: hand;
}

.myFile submit {
  border: 1px solid red !important;
}
.mainFontColor {
  color: #555555;
}
.mainbox {
  background-color: #fafafa;
}
.privateChatList {
  color: hwb(192 26% 13%);
}

.privateChatList:hover {
  color: #05cdff;
}
.messages {
  position: relative;
  height: 100%;
}
.messagesWrap {
  padding: 1px 20px 0px 5px;
}
.drawerElement {
  position: relative;
  vertical-align: top;
  text-align: top;
  top: 0px;
  height: 70vh;
  width: 100%;
}
.chatTitle {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 1em;
  color: #aaa;
}
.note {
  color: gray.500;
  font-size: 0.9em;
}

.settingsTd {
  text-align: center;
  background-color: #ffffff;
}
.taskTd {
  border-right: 1px dashed #dadada !important;
  background-color: #fafafa;
}
.notesTd {
  padding: 6px !important;
  text-align: center;
  background-color: #fafafa;
}
.closeTitle {
  position: relative;
  width: 100%;
  /* border-bottom: 1px solid #efefef; */
}
.commentTd {
  padding: 6px !important;
  background-color: #ffffff;
  border-left: 1px dashed #dddddd !important;
  /* border-right: 1px dashed #dddddd !important; */
}

.tableBox {
  background-color: white;
  border-width: 1px 1px 0px 1px;
  border-color: #efefef;
  border-style: solid;
  /* box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.05); */
}
.drawerContent {
  background-color: #173340;
}
.title {
  font-size: 0.9em;
  color: #206c8a;
}
.doneTitle {
  font-size: 0.9em;
  color: #9fba3c;
}
.title:hover {
  color: #aaaaaa;
}
.hand {
  cursor: pointer;
  cursor: hand;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.privatechatsList {
  position: relative;
  height: 100%;
  border: 1px solid rgb(168, 168, 168);
}
.datepickerwraper {
  position: relative;
  z-index: 1000;
}
.top {
  background-color: #173340;
}
.topDrawerBox {
  display: flex;
  background-color: #173340;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 15px 10px 15px;
  margin: 0 auto;
  border-width: 0px;
}
.small {
  font-size: 0.8em;
}
.smaller {
  font-size: 0.7em;
}
.big {
  font-size: 1.2em;
}
.red {
  color: red;
}
.gray {
  color: gray;
}
.lightgray {
  color: rgb(196, 196, 196);
}
.link {
  text-decoration: underline !important;
  cursor: pointer;
  cursor: hand;
}
